/**
 * @ComponentFor ProductListingPageViewModel
 */

import React from 'react';
import { styled } from '@glitz/react';
import { EpiProperty } from '@avensia/scope-episerver';
import { PageType } from 'Shared/State';
import { sigma, pixelsToUnit, minMediumMediaQuery, delta, eta, alpha } from 'Shared/Style';
import ProductListing from 'Product/ProductListing';
import { Main, Section, Appearance as PageAppearance } from 'Shared/PageLayout';
import Breadcrumbs from 'Shared/Breadcrumbs';
import ProductListingPageType from './ProductListingPageViewModel.type';

type PropType = ProductListingPageType & PageType;

const H1 = styled.h1({
  display: 'block',
  fontSize: delta,
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(0.6),
  lineHeight: 'normal',
  textAlign: 'center',
  textTransform: 'lowercase',
  ':first-letter': {
    textTransform: 'uppercase',
  },
  [minMediumMediaQuery]: {
    display: 'inline-block',
    fontSize: alpha,
    fontWeight: 'normal',
    textAlign: 'left',
  },
});

export default (props: PropType) => {
  const { breadcrumbs, ...restProps } = props;
  return (
    <Main appearance={PageAppearance.Narrow}>
      <ProductListing
        topContent={
          <EpiProperty component={(innerProps) => <Section {...innerProps} />} for={props.content.topContent} />
        }
        breadcrumbs={
          <Breadcrumbs
            css={{
              fontSize: sigma,
              letterSpacing: pixelsToUnit(0.5),
              marginBottom: pixelsToUnit(5),
              textAlign: 'center',
              [minMediumMediaQuery]: {
                fontSize: eta,
                letterSpacing: pixelsToUnit(0.2),
                marginBottom: pixelsToUnit(10),
                textAlign: 'left',
              },
            }}
            breadcrumbs={breadcrumbs}
          />
        }
        pageTitle={<EpiProperty component={(innerProps) => <H1 {...innerProps} />} for={props.content.name} />}
        {...restProps}
      />
    </Main>
  );
};
